import { Pagination, Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { formatDate, formatTime, getNotificationColor } from "./utilities";

import { useNavigate } from "react-router-dom";
import { getNotifications, makeseen } from "../apis/notificationapi";

const Notifications = () => {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const filter = {
    role: userData.loginAs,
    department: userData.department,
  };
  const [page, setPage] = useState(1);
  const [configs, setConfigs] = useState({
    loading: true,
    notifications: null,
    count: 0,
  });
  useEffect(() => {
    const getallNotifications = async () => {
      const resp = await getNotifications({ filter, page });
      setConfigs({
        loading: false,
        notifications: resp.notifications,
        count: resp.count,
      });
    };
    getallNotifications();
  }, [page]);
  const makeNotificationSeen = async (notificationId) => {
    const response = await makeseen({ notificationId });
  };
  console.log("configs, ", configs);
  function routeToObligation(id) {
    window.location.href = `/viewobligation/${id}`;
  }
  return (
    <div>
      <Text className="flex justify-center font-bold text-xl my-4">
        Notifications
      </Text>
      {!configs.loading &&
        configs.notifications &&
        configs.notifications.length && (
          <>
            {configs.notifications.map((notification) => (
              <div
                onClick={async () => {
                  if (!notification.seen) {
                    await makeNotificationSeen(notification.id);
                  }
                  routeToObligation(notification.obligationId);
                }}
                style={{
                  backgroundColor: `${getNotificationColor(
                    notification.userId
                  )}`,
                }}
                className={`w-full mx-2  rounded-2xl`}
              >
                <div  className={notification.seen ? `ml-5 px-3 py-5 bg-white rounded-2xl flex justify-between`:`ml-5 px-3 py-5 bg-blue-100 rounded-2xl flex justify-between` }>
                  <Text className="ml-2 text-base font-500">
                    {notification.notificationText}
                  </Text>
                  <div className="flex justify-between">
                    <div
                      className="flex flex-row mr-2 items-center"
                      style={{ width: "100px" }}
                    >
                      <img src="./assets/calender.svg" alt="calendar" />
                      <div className={` ml-1`}>
                        <Text>{formatDate(notification.createdAt)}</Text>
                      </div>
                    </div>
                    <div
                      className="flex flex-row mr-2 items-center"
                      style={{ width: "80px" }}
                    >
                      <img src="./assets/clock.svg" alt="clock" />
                      <div className={`ml-1`}>
                        <Text>{formatTime(notification.createdAt)}</Text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="flex flex-row justify-center my-4">
              <Pagination
                page={page}
                onChange={(page) => {
                  setPage(page);
                }}
                total={Math.ceil(configs.count / 10)}
              />
            </div>
          </>
        )}
      {!configs.loading && !configs.notifications.length && (
        <Text>no notifications for you...</Text>
      )}
    </div>
  );
};

export default Notifications;
