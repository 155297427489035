import axios from "axios";

export const getNotifications = async ({ filter, page }) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/getNotifications`, {
      ...filter,
      page,
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("There was an error getting notifications", error);
      throw error;
    });
};

export const getUnseenNotificationsCount = async ({ filter }) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/getunseennotificationscount`, {
      ...filter,
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("There was an error getting notifications count", error);
      throw error;
    });
};

export const makeseen = async ({ notificationId }) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/updatenotificationstatus`, {
        notificationId,
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("There was an error updating seen status", error);
      throw error;
    });
};
