import { ActionIcon, Pagination, Select, Table, Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { getDocuments } from "../apis/userApis";
import { IconEye } from "@tabler/icons-react";

const DocumentsModal = ({ setSignedFile, setDocumentsModal }) => {
  const [configs, setConfigs] = useState({
    loading: true,
    documents: null,
    count: 10,
  });
  const [uiConfigs, setUiConfigs] = useState({
    page: 1,
    pageSize: 10,
  });
  useEffect(() => {
    const getAllDocuments = async () => {
      try {
        const response = await getDocuments({
          page: uiConfigs.page,
          pageSize: uiConfigs.pageSize,
        });
        console.log("reponse:", response);
        setConfigs({
          loading: false,
          documents: response.documents,
          count: response.count,
        });
      } catch (error) {
        setConfigs({
          loading: false,
          documents: null,
          count: 10,
        });
      }
    };
    getAllDocuments();
  }, [uiConfigs.page, uiConfigs.pageSize]);
  // fileName: name,
  //     hostedLink: location,
  //     key: data.key,
  //     type: file.type,
  return (
    <>
      <div>
        {!configs.loading && configs.documents.length > 0 && (
          <>
            <Table sthiped>
              <thead>
                <tr>
                  <th>select</th>
                  <th>file name</th>
                  <th>title</th>
                  <th>category</th>
                  <th>subcategory</th>
                  {/* <th>sub-subCategory</th> */}
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {configs.documents.map((document) => (
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        onChange={() => {
                          setSignedFile({
                            name: document.name,
                            fileName: document.key,
                            hostedLink: document.url,
                            key: document.key,
                            type: "application/pdf",
                          });
                          setDocumentsModal(false);
                        }}
                      />
                    </td>
                    <td>{document.name}</td>
                    <td>{document.title}</td>
                    <td>{document.category}</td>
                    <td>{document.SubCategory}</td>
                    {/* <td></td> */}
                    <td>
                      <ActionIcon>
                        <IconEye color="blue" size={20} />
                      </ActionIcon>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="flex justify-center">
              <Pagination
                page={uiConfigs.page}
                onChange={(page) => setUiConfigs({ ...uiConfigs, page: page })}
                total={Math.ceil(configs.count / uiConfigs.pageSize)}
              />
              <Select
                style={{
                  width: "70px",
                }}
                className="ml-4"
                data={["10", "20", "50"]}
                value={uiConfigs.pageSize + ""}
                onChange={(value) =>
                  setUiConfigs({
                    ...uiConfigs,
                    pageSize: parseInt(value),
                  })
                }
              />
            </div>
          </>
        )}
        {!configs.loading && configs.documents.length === 0 && (
          <Text>no documents</Text>
        )}
      </div>
    </>
  );
};

export default DocumentsModal;
