import React, { useEffect, useState } from "react";
import AppHeader from "./Header";
import {
  AppShell,
  Header,
  Navbar,
  ActionIcon,
  ScrollArea,
} from "@mantine/core";
import {
  IconArrowBadgeRight,
  IconBadge,
  IconBell,
  IconCircleCheck,
  IconFileReport,
  IconFileTypePdf,
  IconHome,
  IconLayoutSidebarLeftCollapse,
  IconLayoutSidebarRightCollapse,
  IconLock,
  IconPlus,
  IconProgress,
  IconReport,
  IconReportAnalytics,
  IconTimeline,
  IconUsers,
} from "@tabler/icons-react";
import { IconFile } from "@tabler/icons-react";
import { getUnseenNotificationsCount } from "../apis/notificationapi";


const SideNav = ({ appContent }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  // const userData = JSON.parse(localStorage.getItem("userData"));
  const [unseen, setUnseen] = useState(null);

  const filter = {
    role: userData?.loginAs, // Use optional chaining to prevent errors if `userData` is null or undefined
    department: userData?.department,
  };

  useEffect(() => {
    const getUnseenCount = async () => {
      try {
        const response = await getUnseenNotificationsCount({ filter });
        setUnseen(response?.count || 0); 
      } catch (error) {
        console.error("Error fetching unseen notifications count:", error);
      }
    };

    if (filter.role && filter.department) {
      // Ensure filter fields are valid before making the request
      getUnseenCount();
    }
  }, [filter.role , filter.department]);

  return (
    <>
      <AppShell
        navbar={
          <Navbar
            style={{ backgroundColor: "#F1F4FA" }}
            width={{ base: sidebarCollapsed ? 50 : 240 }}
            height="calc(100vh - 65px)"
          >
            <Navbar.Section grow component={ScrollArea}>
              <div className="flex flex-col">
                <div className="flex justify-end mt-2">
                  <ActionIcon
                    color="blue"
                    onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
                    style={{ cursor: "pointer", marginRight: "9%" }}
                  >
                    {sidebarCollapsed ? (
                      <IconLayoutSidebarRightCollapse
                        color="#46bde1"
                        size={25}
                      />
                    ) : (
                      <IconLayoutSidebarLeftCollapse
                        color="#46bde1"
                        size={25}
                      />
                    )}
                  </ActionIcon>
                </div>
                {!sidebarCollapsed && (
                  <div className="flex flex-col p-3">
                    <div className="space-y-3">
                      <div className="flex-1">
                        <ul className=" pb-4 space-y-3 text-sm">
                          {userData.loginAs !== "Ob-Auditor" && (
                            <li className="rounded-sm">
                              <a
                                href="/dashboard"
                                className="flex items-center p-2 space-x-4 rounded-md"
                              >
                                <IconHome
                                  style={{ padding: "2px" }}
                                  color="#46bde1"
                                  size={25}
                                />
                                <span style={{ fontWeight: "500" }}>
                                  dashboard
                                </span>
                              </a>
                            </li>
                          )}
                          {(userData.loginAs === "Ob-DeptUser" ||
                            userData.loginAs === "Ob-DeptAdmin" ||
                            userData.loginAs === "Ob-Admin" ||
                            userData.loginAs === "Ob-Auditor") && (
                            <li className="rounded-sm">
                              <a
                                href="/notifications"
                                className="flex justify-between"
                              >
                                <div className="flex  p-2 space-x-4 rounded-md">
                                <IconBell
                                  style={{ padding: "2px" }}
                                  color="#46bde1"
                                  size={25}
                                />
                                <span  style={{ fontWeight: "500", }}>
                                 notifications
                                </span>
                                </div>
                                <div className="flex justify-center mt-2">
                                <span className="rounded  text-center mr-2" style={{ borderRadius: "50%", textAlign: "center", fontWeight: "500", backgroundColor: "#46bde1", width: "22px", height: "22px", lineHeight: "20px", color:"white", padding: "1px" }}>{unseen!== null && unseen}</span>
                                </div>
                              </a>
                            </li>
                          )}
                          {(userData.loginAs === "Ob-Admin" ||
                            userData.loginAs === "Ob-Editor" ||
                            userData.loginAs === "Ag-Editor") && (
                            <li className="rounded-sm">
                              <a
                                href="/new"
                                className="flex items-center p-2 space-x-4 rounded-md"
                              >
                                <IconPlus
                                  style={{ padding: "2px" }}
                                  color="#46bde1"
                                  size={25}
                                />
                                <span style={{ fontWeight: "500" }}>
                                  new obligation
                                </span>
                              </a>
                            </li>
                          )}
                          {userData.loginAs === "Ob-DeptAdmin" && (
                            <li className="rounded-sm">
                              <a
                                href="/acceptobligation"
                                className="flex items-center p-2 space-x-4 rounded-md"
                              >
                                <IconCircleCheck
                                  style={{ padding: "2px" }}
                                  color="#46bde1"
                                  size={25}
                                />
                                <span style={{ fontWeight: "500" }}>
                                  accept obligations
                                </span>
                              </a>
                            </li>
                          )}
                          {(userData.loginAs === "Ob-DeptUser" ||
                            userData.loginAs === "Ob-DeptAdmin") && (
                            <li className="rounded-sm">
                              <a
                                href="/assigned"
                                className="flex items-center p-2 space-x-4 rounded-md"
                              >
                                <IconReport
                                  style={{ padding: "2px" }}
                                  color="#46bde1"
                                  size={25}
                                />
                                <span style={{ fontWeight: "500" }}>
                                  my obligations
                                </span>
                              </a>
                            </li>
                          )}
                          {(userData.loginAs === "Ob-DeptUser" ||
                            userData.loginAs === "Ob-DeptAdmin") && (
                            <li className="rounded-sm">
                              <a
                                href="/actioned"
                                className="flex items-center p-2 space-x-4 rounded-md"
                              >
                                <IconProgress
                                  style={{ padding: "2px" }}
                                  color="#46bde1"
                                  size={25}
                                />
                                <span style={{ fontWeight: "500" }}>
                                  actioned obligations
                                </span>
                              </a>
                            </li>
                          )}
                          {(userData.loginAs === "Ob-Editor" ||
                            userData.loginAs === "Ob-DeptAdmin" ||
                            userData.loginAs === "Ob-CXO" ||
                            userData.loginAs === "Ag-Editor" ||
                            userData.loginAs === "Ob-CEO" ||
                            userData.loginAs === "Ob-COO" ||
                            userData.loginAs === "Ob-Admin" ||
                            userData.loginAs === "Ob-Auditor") && (
                            <li className="rounded-sm">
                              <a
                                href="/obligationtracker"
                                className="flex items-center p-2 space-x-4 rounded-md"
                              >
                                <IconReportAnalytics
                                  style={{ padding: "2px" }}
                                  color="#46bde1"
                                  size={25}
                                />
                                <span style={{ fontWeight: "500" }}>
                                  obligations monitor
                                </span>
                              </a>
                            </li>
                          )}
                          {(userData.loginAs === "Ob-Editor" ||
                            userData.loginAs === "Ob-Admin" ||
                            userData.loginAs === "Ag-Editor") && (
                            <li className="rounded-sm">
                              <a
                                href="/docslocker"
                                className="flex items-center p-2 space-x-4 rounded-md"
                              >
                                <IconLock
                                  style={{ padding: "2px" }}
                                  color="#46bde1"
                                  size={25}
                                />
                                <span style={{ fontWeight: "500" }}>
                                  agreements locker
                                </span>
                              </a>
                            </li>
                          )}
                          {/* <li className="rounded-sm">
                            <a
                              href="/documents"
                              className="flex items-center p-2 space-x-4 rounded-md"
                            >
                              <IconFile
                                style={{ padding: "2px" }}
                                color="#46bde1"
                                size={25}
                              />
                              <span style={{ fontWeight: "500" }}>
                                documents
                              </span>
                            </a>
                          </li> */}
                          {(userData.loginAs === "Ob-Admin" ||
                            userData.loginAs === "Ob-DeptAdmin") && (
                            <li className="rounded-sm">
                              <a
                                href="/team"
                                className="flex items-center p-2 space-x-4 rounded-md"
                              >
                                <IconUsers
                                  style={{ padding: "2px" }}
                                  color="#46bde1"
                                  size={25}
                                />
                                <span style={{ fontWeight: "500" }}>
                                  team manager
                                </span>
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Navbar.Section>
          </Navbar>
        }
        header={
          <Header height={65} style={{ background: "#FFFFFF" }}>
            <AppHeader />
          </Header>
        }
      >
        <div
          style={{
            backgroundColor: "#FFFFFF",
            minHeight: "calc(100vh - 100px)",
          }}
        >
          {appContent}
        </div>
      </AppShell>
    </>
  );
};

export default SideNav;

// 21
{
  /* <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 17V1m0 0L1 4m3-3 3 3m4-3h6l-6 6h6m-7 10 3.5-7 3.5 7m-6.125-2H16"/>
</svg> */
}

// 31
// <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 17 14">
//     <path d="M16 2H1a1 1 0 0 1 0-2h15a1 1 0 1 1 0 2Zm0 6H1a1 1 0 0 1 0-2h15a1 1 0 1 1 0 2Zm0 6H1a1 1 0 0 1 0-2h15a1 1 0 0 1 0 2Z"/>
// </svg>
